import React from 'react'
import {graphql, navigate} from 'gatsby'
import {Global} from '@emotion/core'
import {FixedObject} from 'gatsby-image'
import {minutesInMs} from '@arcath/utils'

import {Widget, WidgetType} from '../components/widget'

import 'typeface-oxanium'
import 'typeface-lato'

const ScreenTemplate: React.FC<{
  data: {
    screen: {
      id: number
      name: string
      background_color: string
      background: {
        localFile:{ 
          childImageSharp: { 
            fixed: FixedObject
          }
        }
      }
      duration: number
      widgets: {
        x: number
        y: number
        width: number
        height: number
        display_title: boolean
        title: string
        type: WidgetType
        id: number
        background_color: string
        transparent: boolean
        content: string
        text_color: string
        options: {
          string?: string
          display?: string
        }
        gallery?: {
          images: {
            localFile: {
              childImageSharp: {
                fluid: any
              }
            }
          }[]
        }
      }[]
      next_screen?: {
        id: string
        fields: {
          slug: string
        }
      }
    }
  }
}> = ({data}) => {
  const {screen} = data

  if(screen.next_screen && screen.duration !== 0){
    setTimeout(() => {
      navigate('/' + screen.next_screen.fields.slug)
    }, minutesInMs(screen.duration))
  }

  if(typeof window !== `undefined`){
    // If we are in browser

    if(!(window as any).builtAt){
      // If this is the first screen to load

      fetch('/build.json')
        .then(response => response.json())
        .then((data) => {
          (window as any).builtAt = data.builtAt
        })
    }else{
      // Already have a built at lets validate
      fetch('/build.json')
        .then(response => response.json())
        .then((data) => {
          if(data.builtAt !== (window as any).builtAt){
            location.reload()
          }
        })
    }

  }

  return <>
    <Global styles={
      {
        body: {
          backgroundColor: screen.background_color,
          backgroundImage: `url(${screen.background.localFile.childImageSharp.fixed.src})`,
          backgroundPosition: 'center center',
          overflow: 'hidden',
          fontFamily: 'Lato',
          minHeight: '100vh'
        }
      }
    } />
    {screen.widgets.map((widget) => {
      return <Widget key={widget.id} widget={widget} />
    })}
  </>
}

export default ScreenTemplate

export const pageQuery = graphql`
  query ScreenTemplateQuery($slug: String!){
    screen: directusScreen(fields: {slug: {eq: $slug}}){
      id
      name
      background_color
      background{
        localFile{
          childImageSharp {
            fixed(width: 1920, height: 1080){
              src
            }
          }
        }
      }
      duration
      widgets {
        x
        y
        width
        display_title
        height
        id
        title
        type
        background_color
        transparent
        content
        text_color
        options{
          string
          display
        }
        gallery {
          images {
            localFile {
              childImageSharp {
                fluid(maxHeight: 1080, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      next_screen{
        id
        fields{
          slug
        }
      }
    }
  }
`