import React, {useEffect, useState} from 'react'
import styled from '@emotion/styled'
//import Marquee from 'react-double-marquee'
import {format} from 'date-fns'
import Img from 'gatsby-image'

import {Marquee} from './marquee'

export type WidgetType =
  "html" |
  "marquee" |
  "clock" |
  "gallery"

const WidgetDiv = styled.div<{x: number, y: number, width: number, height: number, transparent: boolean, background_color: string, text_color: string}>`
  ${({x, y, width, height, transparent, background_color, text_color}) => {
    return `
      position:absolute;
      top:${y}vh;
      left:${x}vw;
      width:${width}vw;
      height:${height}vh;
      ${transparent ? '' : `background-color:${background_color};`}
      border-radius:10px;
      overflow:hidden;
      color:${text_color};
    `
  }}
`

const ClockDiv = styled.div`
  text-align:center;
  font-family:'oxanium';
`

export const Widget: React.FC<{widget: {
  x: number
  y: number
  width: number
  height: number
  display_title: boolean
  title: string
  type: WidgetType
  background_color: string
  transparent: boolean
  content: string
  text_color: string
  options: {
    string?: string
    display?: string
  }
  gallery?: {
    images: {
      localFile: {
        childImageSharp: {
          fluid: any
        }
      }
    }[]
  }
}}> = ({widget}) => {

  const content = () => {
    switch(widget.type){
      case 'html':
        return <div dangerouslySetInnerHTML={{__html: widget.content}} />
      case 'marquee':
        return <Marquee velocity={0.12}><div dangerouslySetInnerHTML={{__html: widget.content}} /></Marquee>
      case 'clock':
        return <Clock display={widget.options.display!} />
      case 'gallery':
        return <Gallery images={widget.gallery.images} />
    }
  }

  return <WidgetDiv {...widget}>
    {widget.display_title ? <h2>{widget.title}</h2> : ''}
    {content()}
  </WidgetDiv>
}

const Clock: React.FC<{display: string}> = ({display}) => {
  const [curTime, setCurTime] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setCurTime(new Date())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  return <ClockDiv>
    {format(curTime, 'hh:mm:ss a')}
  </ClockDiv>
}

const Gallery: React.FC<{
  images: {
    localFile: {
      childImageSharp: {
        fluid: any
      }
    }
  }[]
}> = ({images}) => {
  const [image, setImage] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const next = image + 1

      setImage(next === images.length ? 0 : next)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  })

  return <>
    <Img fluid={images[image].localFile.childImageSharp.fluid} />
  </>
}