import React, {useState, useRef, useEffect} from 'react'
import styled from '@emotion/styled'

const useAnimationFrame = (callback: (deltaT: number) => void) => {
  const requestRef = useRef<number>()
  const previousTimeRef = useRef<number>()
  
  const animate = (time: number) => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current
      callback(deltaTime)
    }
    previousTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
  }
  
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
  }, [])
}

const MarqueeContainer = styled.div`
  overflow:hidden;
`

const MarqueeInner = styled.div`
  display:inline-block;
`

export const Marquee: React.FC<{velocity: number}> = ({velocity, children}) => {
  const [offsetX, setOffsetX] = useState(0)
  const inner = useRef<any>()
  const outer = useRef<any>()

  useAnimationFrame((delta) => {
    setOffsetX((p) => {
      let newOffsetX = p - (delta * velocity)

      if(newOffsetX + inner.current.clientWidth < 0){
        newOffsetX = outer.current.clientWidth
      }

      return newOffsetX
    })
  })

  return <MarqueeContainer ref={outer}>
    <MarqueeInner ref={inner} style={{transform: `translateX(${offsetX}px)`}}>{children}</MarqueeInner>
  </MarqueeContainer>
}
